export default {
  submit: 'Submit',
  footer: 'Copyright © Synadia. All Rights Reserved',
  welcome: 'Welcome',
  username: 'Username',
  confirm: 'Confirm',
  select: 'Select',
  email: 'Email Address',
  cancel: 'Cancel',
  no_data: 'No data available',
  not_available: 'n/a',
  date_select: 'Date shortcuts',
  date_from: 'Date From',
  date_to: 'Date To',
  this_week: 'This week',
  this_month: 'This month',
  last_7_days: 'Past 7 days',
  last_30_days: 'Past 30 days',
  agree: 'Agree',
  auth: {
    sign_in: 'Sign In',
    reset_pass: 'Reset password',
    back_login: 'Back to Log in',
    resend_code: 'Resend Code',
    new_pass: 'New Password',
    confirm_pass: 'Confirm Password',
    confirm_code: 'Verification code',
    change_pass: 'Change Password',
    pass: 'Password',
    email_phone: 'Email or phone number',
    save_creds: 'Save Credentials',
    cookies:
      'This website uses cookies. By accepting this website you consent to our use of cookies'
  },
  dash: {
    title: 'Dashboard',
    shifts: 'OEE Daily Breakdown',
    assets_preview: 'Asset State',
    description: 'Factory Floor Dashboard',
    subtitle: 'Overview',
    counter_current: 'Actual',
    counter_expected: 'Expected',
    counter_target: 'Target',
    oee: 'OEE',
    availability: 'Availability',
    quality: 'Quality',
    performance: 'Performance',
    statistics: 'Asset state (%)',
    top_asset: 'Top performer (last hour)',
    slow_asset: 'Slow performer (last hour)'
  },
  users: {
    title: 'Users',
    create_user: 'Create user',
    disable_user: 'Disable user?',
    new_pass_descr: 'To reset password for the user, provide a new password',
    create_user_success: 'User successfully created'
  },
  user: {
    title: 'User',
    edit: 'Edit user',
    role: 'Role'
  },
  assets: {
    title: 'Assets',
    table: 'Assets'
  },
  asset: {
    title: 'Asset',
    bars_title: 'Asset overview',
    trends: 'Trends',
    oee: 'OEE',
    idle: 'Idle',
    blocked: 'Blocked',
    running: 'Running',
    stopped: 'Stopped',
    fault: 'Fault'
  },
  home: {
    title: 'Home'
  },
  settings: {
    title: 'Settings'
  },
  errors: {
    404: '404',
    error_page_title: 'Oops. Nothing here...',
    required: 'Field is required',
    to_long: 'Field is too long',
    to_short: 'Field is too short',
    whitespaces: 'Field contains only whitespaces',
    email: 'E-mail must be valid',
    pass_short: 'Password is too short',
    pass_number: 'Password should contain at least 1 number',
    pass_uppercase: 'Password should contain at least 1 uppercase letter',
    pass_lowercase: 'Password should contain at least 1 lowercase letter',
    code_mismatch: 'Provided code does not match what the server was expecting',
    user_not_found: 'User not found or it was disabled',
    code_expired: 'Code has expired',
    not_authorized: 'The user is not authorized',
    username_taken: 'This username already exists in the user pool',
    failed_req: 'Request failed. Please try again',
    local_storage: "Data wasn't saved to local storage. Please try again.",
    req_permissions: "Couldn't fetch permissions. Please try again",
    req_user: "Couldn't fetch user data. Please try again",
    sign_in: 'Failed to sign in. Please try again',
    disable: 'Failed to disable the user. Please try again',
    update_groups: 'Failed to update user groups. Please try again',
    update_pass: 'Failed to update user password. Please try again',
    create_user: 'Failed to create user. Please try again',
    logout: 'Failed to logout. Please try again',
    failed_redirect: 'You don`t have permissions to visit this page',
    cookies: 'Please accept our cookie statement before you sign in'
  }
}
