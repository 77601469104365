import { Component, Mixins } from 'vue-property-decorator'
import { AbilityBuilder } from '@casl/ability'
import { CurrentUser, UserGroup, AbilitiesMap } from 'src/types/models/index'
import CommonMixin from 'src/mixins/common-mixin'
import { Hub } from 'aws-amplify'

@Component
export default class PermissionsMixin extends Mixins(CommonMixin) {
  user: any = null

  created() {
    this.assignPermissions()
    this.onSignInEvent()
  }

  defineRulesFor(roles: UserGroup[]) {
    const { can, cannot, rules } = new AbilityBuilder()

    const allPages = [
      'home',
      'dashboard',
      'assets',
      'asset',
      'settings',
      'users'
    ]

    cannot('view', allPages)

    const userAllowedPages = [
      'home',
      'dashboard',
      'assets',
      'asset',
      'settings'
    ]

    const abilities: AbilitiesMap = {
      Users: userAllowedPages,
      AdminUsers: ['users'],
      TvUsers: []
    }

    roles.forEach((role: UserGroup) => can('view', abilities[role.groupname]))

    this.$ability.update(rules)
  }

  onSignInEvent() {
    const listener = (data: any) => {
      if (data.payload.event === 'signIn') {
        this.assignPermissions()
      }
    }
    Hub.listen('auth', listener)
  }

  async assignPermissions(path?: any) {
    this.globalLoading = 1
    const currentUser: any = this.$q?.localStorage.getItem('user')
    if (currentUser) {
      this.defineRulesFor(currentUser.usergroups)
    } else {
      this.user = await this.setCurrentUser()
      if (this.user) {
        const formattedGroups = this.getUserGroups()
        const newCurrentUser = this.getUserPayload(formattedGroups)
        this.saveToLocalStorage(newCurrentUser)
        this.defineRulesFor(newCurrentUser.usergroups)
      }
    }
    if (path) {
      this.performRedirect(path)
    }
    this.globalLoading = 0
  }

  performRedirect(path: any) {
    if (this.$ability.cannot('view', 'dashboard')) {
      this.notifyNegative('errors.failed_redirect')
      this.logout()
    } else {
      this.$router.push(path)
    }
  }

  getUserGroups() {
    const userGroups = this.user.signInUserSession.accessToken.payload[
      'cognito:groups'
    ]
    if (userGroups) {
      return userGroups.map((group: any) => ({
        groupname: group
      }))
    }
  }

  setCurrentUser() {
    return this.$Auth
      .currentAuthenticatedUser()
      .then((user: any) => user)
      .catch((e: any) => this.notifyNegative('errors.failed_req', e))
  }

  getUserPayload(userGroups: UserGroup[]): CurrentUser {
    const now = new Date()
    const currentDateTime = now.toISOString()
    return {
      currentDateTime,
      usergroups: userGroups,
      username: this.user.username
    }
  }

  saveToLocalStorage(user: CurrentUser) {
    try {
      this.$q.localStorage.set('user', user)
    } catch (e) {
      this.notifyNegative('error.local_storage', e)
    }
  }
}
