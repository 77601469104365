import { Component, Watch, Vue } from 'vue-property-decorator'
import { Loading } from 'src/types/models/index'

@Component
export default class CommonMixin extends Vue {
  loading: Loading = 0
  globalLoading: Loading = 0

  @Watch('globalLoading')
  emitLoading(val: Loading) {
    const loadingConfig = {
      customClass: this.isMainLayout,
      spinnerColor: 'primary',
      backgroundColor: 'bg',
      spinnerSize: 50
    }
    val ? this.$q.loading.show(loadingConfig) : this.$q.loading.hide()
  }

  get isMainLayout() {
    return this.$route.matched.some(route => route.meta.mainLayout)
      ? 'loading-main'
      : ''
  }

  logout() {
    this.$Auth
      .signOut()
      .then(() => {
        this.$q.localStorage.clear()
        if (this.$route.name !== 'signIn') this.$router.push({ name: 'signIn' })
      })
      .catch((e: any) => this.notifyNegative('errors.failed_req', e))
  }

  notifyPositive(message: string) {
    this.$q.notify({
      color: 'positive',
      icon: 'fal fa-thumbs-up',
      message: this.$i18n.tc(message)
    })
  }
  notifyNegative(message: string, err?: any) {
    this.$q.notify({
      color: 'negative',
      icon: 'fal fa-thumbs-down',
      message: this.$i18n.tc(message)
    })
    if (err) console.error(err)
  }
}
